g {
  stroke: #999;
  fill: #bbb;
  stroke-width: 1.5px;
}

g.circle {
  stroke: #999;
  fill: #fff;
  stroke-width: 1.5px;
}

g.leftedge {
  stroke: #999;
  fill: red;
  font-size: 14px;
}

g.rightedge {
  stroke: #999;
  fill: green;
  stroke-width: 1.5px;
}

text {
  font-weight: 300;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
}

.node rect {
  stroke: #999;
  fill: #ffe;
  stroke-width: 1.5px;
  text-align: center;

}

.edgePath path {
  stroke: #333;
  stroke-width: 1.5px;
}
